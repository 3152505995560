:root {
 --size-title: 3.6rem;
 --size-subtitle: 3rem;
 --size-text: 2.2rem;
 --size-mini: 1.8rem;
 --size-statement: 2.6rem;
 --main-color: #6e0d94;
 --font-color: #ffffff;
 --font-subcolor: #fecc1b;
 --color2: #a301e7;
 --color3: #ee1a39;
 --color4: rgb(75, 173, 253);
 --color5: #4c426e;
 --slot1: #ff5958;
 --slot2: #2aa552;
 --slot3: #2d3dd2;
 --slot4: #3493b9;
 --slot5: #fd7e20;
 --curve: 50px;
}

@media screen and (max-width: 1070px) {
 .container-CD {
  display: inline;
 }
 .title1-CD {
  padding-top: 50px;
  margin: 0px auto 50px auto;
 }

 .text1-CD {
  margin: auto;
  width: 90%;
 }
 .img1-CD {
  padding-top: 0;
  width: 280px;
  padding-left: 2rem;
  margin: auto;
 }
 .container2-CD {
  display: inline;
 }
 .text2-CD {
  margin: auto;
  width: 90%;
 }
 .title2-CD {
  display: flex;
  justify-content: center;
  margin: 50px auto 50px auto;
 }
 .img2-CD {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 260px;
  padding-top: 0;
  margin: auto;
  padding-bottom: 5rem;
 }

 .text1-use > p {
  width: 90%;
  margin: auto;
  padding-top: 5rem;
 }

 .text2-use > ul {
  margin-top: 4rem;
  padding-left: 60px;
 }

 .bg-p {
  padding-bottom: 6rem;
 }

 .title_p {
  margin: auto 14% 4rem;
 }

 .list_p {
  margin: 0rem 5rem 2rem 3rem;
 }

 .container-A {
  margin-left: 3rem;
  margin-top: 0;
 }

 .img_list {
  margin-right: 2rem;
 }
}

@media screen and (max-width: 480px) {
 .title {
  font-size: 3rem;
 }

 .subtitle {
  font-size: 2.6rem;
 }

 .title1-CD {
  width: 320px;
  font-size: var(--size-mini);
  text-align: center;
  margin-bottom: 3rem;
 }

 .text1-CD {
  width: 310px;
  margin-left: 3rem;
  font-size: 1.4rem;
  margin-bottom: 3rem;
 }

 .img1-CD {
  width: 180px;
  height: auto;
 }

 .title2-CD {
  font-size: var(--size-mini);
  padding-top: 0;
  margin: 0rem auto 3rem;
 }

 .text2-CD {
  width: 300px;
  padding: 4rem;
  font-size: 1.4rem;
  padding-bottom: 1rem;
 }

 .img2-CD {
  width: 300px;
  height: auto;
  margin-top: 0;
  padding-bottom: 4rem;
 }

 .title1-use {
  font-size: var(--size-mini);
 }

 .text1-use > p {
  padding-top: 2.6rem;
  font-size: 1.4rem;
  margin: 0rem 2.8rem;
 }

 .title2-use {
  font-size: var(--size-mini);
  margin: -1rem 0rem 0rem 1.6rem;
  text-align: center;
  padding-bottom: 0rem;
 }

 .text2-use > ul {
  font-size: 2rem;
  padding: 0rem 3.8rem;
  margin: 3rem 0rem 0rem 1rem;
  font-size: 1.4rem;
 }

 .list_p {
  margin: 25px;
 }

 .title_p {
  font-size: var(--size-mini);
  text-align: center;
  margin-bottom: 0px;
 }

 .text_p {
  font-size: 1rem;
  margin-top: 0;
 }

 /* .img_A {
  width: 200px;
 } */

 .img_list {
  width: 36px;
  height: 28px;
  margin-right: 10px;
 }
}

@media screen and (max-width: 480px) {
 .img_cover {
  width: 320px;
 }

 .title {
  font-size: 2rem;
  padding: 30px;
 }

 .textbox {
  font-size: 1.25rem;
  width: 100%;
  justify-content: center;
  display: flex;
  text-align: center;
  margin-bottom: 0rem;
 }

 .subtitle {
  font-size: 1.6rem;
 }

 .img_cover {
  width: 170px;
 }

 .symbol_cruz {
  width: 80px;
 }

 .title1-CD {
  font-size: 1.25rem;
  width: 260px;
  margin: 0px;
 }

 .text1-CD {
  width: 270px;
  font-size: 1rem;
 }

 .title2-CD {
  font-size: 1.25rem;
  margin: 0em;
 }

 .text2-CD {
  padding: 1em;
 }

 .text2-CD {
  font-size: 1rem;
  width: auto;
  padding: 1rem;
 }

 .text2-CD > p:nth-child(2) {
  padding-left: 2rem;
  padding-right: 2rem;
 }

 .img1-CD {
  width: 120px;
  margin-bottom: 2rem;
 }

 .img2-CD {
  width: 180px;
 }

 .paso-A {
  font-size: 1.8rem;
 }

 .title-A {
  font-size: 2rem;
 }

 .slot {
  font-size: 1rem;
  padding-top: 2rem;
 }

 .text1-use > p {
  font-family: "FoundersGrotesk-Regular";
  font-size: 1rem;
  margin-top: 0rem;
  padding-top: 1.25rem;
 }

 .title1-use {
  padding-top: 3rem;
  font-size: 1.25rem;
  font-family: "FoundersGrotesk-Regular";
 }

 .title2-use {
  padding-top: 3rem;
  font-size: 1.25em;
  width: inherit;
  font-family: "FoundersGrotesk-Regular";
 }

 .text2-use > ul {
  margin: 2rem 0rem 0rem 0rem;
  font-size: 1rem;
  font-family: Founder;
  font-family: "FoundersGrotesk-Regular";
 }

 .title_p {
  font-family: "FoundersGrotesk-Regular";
  font-size: 1.25rem;
 }

 .text_p {
  font-size: 1rem;
  font-family: "FoundersGrotesk-Regular";
 }
}

@media screen and (min-width: 1080px) {
    
    .supportlink_bg, .info-section {
        margin-top: -120px;
    }
}