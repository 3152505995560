@media screen and (max-width: 480px) {
 .title-A {
  font-family: FoundersGrotesk-Bold;
  color: var(--color3);
  font-size: 2rem;
  justify-content: center;
  padding-top: 4rem;
  margin-left: 2rem;
  width: 240px;
 }

 .paso-A {
  font-size: 2.2rem;
  padding-top: 4rem;
 }

 .img_A {
  width: 36px;
  padding-top: 3rem;
 }

 main {
  margin-top: -8rem;
 }

 .img_consult {
  padding-top: 1rem;
  padding-bottom: 5rem;
 }

 .title-B {
  font-size: 2rem;
  justify-content: center;
  margin-left: 2rem;
  width: 240px;
  margin-bottom: 3rem;
 }

 .paso-B {
  font-size: 2rem;
 }

 .img_B {
  width: 36px;
  padding-top: 5rem;
  margin-bottom: 0;
 }

 .bx {
  margin-top: -13rem;
 }

 .img_consult {
  width: 120px;
 }

 .title-C {
  font-size: 2rem;
  justify-content: center;
  margin-left: 2rem;
  width: 240px;
 }

 .paso-C {
  font-size: 2.2rem;
 }

 .img_C {
  width: 36px;
  padding-top: 7.5rem;
  margin-bottom: 0;
 }

 .cx {
  margin-top: -10rem;
 }

 .support {
  width: 180px;
 }

 .tag {
  font-size: 1rem;
  height: 1.4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 10px;
 }

 .slot {
  font-size: 1rem;
  height: auto;
  padding: 1.6rem;
 }

 .title-D {
  font-size: 2rem;
  justify-content: center;
  margin-left: 2rem;
  width: 240px;
  margin-bottom: 1.75rem;
 }

 .paso-D {
  font-size: 2.2rem;
 }

 .img_D {
  width: 36px;
  padding-top: 6rem;
  margin-bottom: 0;
 }

 .dx {
  margin-top: -12rem;
 }

 .remote_support {
  width: 160px;
  padding-bottom: 10rem;
 }

 .group_support {
  width: 90px;
  padding-bottom: 10.5rem;
  margin-top: 0;
 }

 .bg-E {
  margin-top: -7rem;
 }

 .title-E {
  font-size: 2rem;
  justify-content: center;
  margin-left: 2rem;
  width: 240px;
 }

 .paso-E {
  font-size: 2.2rem;
 }

 .img_E {
  width: 36px;
  margin-bottom: 1rem;
 }

 .ex {
  margin-top: -11rem;
 }

 .education_psy {
  width: 160px;
  padding-top: 2rem;
 }

 .title_cons {
  font-size: 1.25rem;
 }

 .const_bg {
  padding: 20px 30px;
  background-color: var(--color3);
  color: white;
 }

 .const_bg > ul > li {
  display: block;
  font-size: 1rem;
  padding: 0;
  margin-bottom: 20px;
 }

 .title_do {
  font-size: 1.25rem;
 }

 .do_element > p {
  font-size: 1rem;
 }

 .check {
  width: 20px;
  height: 18px;
 }

 .title_dont {
  font-size: 1.25rem;
 }

 .dont_element > p {
  font-size: 1rem;
 }
 .dont_symbol {
  width: 18px;
  height: 18px;
 }

 .title_support {
  font-size: 1.25rem;
 }

 .support_element {
  margin-left: 0;
 }

 .support_element > p {
  font-size: 1rem;
 }

 .imgs {
  width: 36px;
  height: 36px;
  margin-bottom: 0;
 }

 .title_slink {
  font-size: 1.25rem;
 }

 .info-credits h2 {
  font-size: 1.25rem;
}

 .slink_element > img {
  width: 90px;
  height: 40px;
 }

 .heart {
  width: 46px;
 }

 .main_slink {
  margin-top: -3rem;
 }

 .slink_element > p {
  margin-top: 0;
  font-size: 1rem;
 }
}
